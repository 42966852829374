<template>
  <div>
    <div class="title mb30">
      <span></span>
      <span>健康码检测情况</span>
      <span>{{ename}}</span>
    </div>
    <div class="content">
      <div class="one">
        <span>{{list.greenNum}}<br /><span>绿码(人)</span></span>
        <div class="img"></div>
      </div>
      <div class="two">
        <span>{{list.yelloNum}}<br /><span>黄码(人)</span></span>
        <div class="img"></div>
      </div>
      <div class="three">
        <span>{{list.redNum}}<br /><span>红码(人)</span></span>
        <div class="img"></div>
      </div>
    </div>
    <div class="bot"></div>
  </div>
</template>

<script>
import { getCode } from '@/api/whole'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      ename: 'Health code detection',
      //数据
      list: {}
    }

  },
  created () {
    this.ename = this.ename.toUpperCase()
    this.getCode()
    this.timer = setInterval(() => {
      this.getCode()
    }, 180000)
  },
  methods: {
    async getCode () {
      let { data } = await getCode()
      this.list = data
      this.$emit('greenNum',this.list.greenNum)
    }
  }

}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 40px;
  margin-bottom: 6px;
  margin-left: 12%;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.content {
  display: flex;
  width: 88%;
  background: rgba(0, 0, 0, 0);
  padding-left: 15%;
  justify-content: space-between;
  .one {
    flex: 0.31;
    height: 130px;
    text-align: center;
    .img {
      background: url("../../../assets/img/切图4.0/编组 12 (1).png") no-repeat;
      background-size: 100%;
      height: 100px;
      position: relative;
      bottom: 30px;
      z-index: 1;
    }
    span {
      width: 112px;
      height: 48px;
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;
      color: #08f4da;
      line-height: 30px;
      position: relative;
      top: 22px;
      z-index: 3;
      span {
        width: 59px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }
  .two {
    flex: 0.31;
    height: 130px;
    text-align: center;
    .img {
      background: url("../../../assets/img/切图4.0/编组 12备份.png") no-repeat;
      background-size: 100%;
      height: 100px;
      position: relative;
      bottom: 30px;
      z-index: 1;
    }
    span {
      width: 112px;
      height: 48px;
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;

      color: #f99c14;
      line-height: 30px;
      position: relative;
      top: 22px;
      z-index: 3;
      span {
        width: 59px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }
  .three {
    flex: 0.31;
    height: 130px;
    text-align: center;
    .img {
      background: url("../../../assets/img/切图4.0/编组 12备份 2.png") no-repeat;
      background-size: 100%;
      height: 100px;
      position: relative;
      bottom: 30px;
      z-index: 1;
    }
    span {
      width: 112px;
      height: 48px;
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;

      color: #f91429;
      line-height: 30px;
      position: relative;
      top: 22px;
      z-index: 3;
      span {
        width: 59px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }
  .bot {
    background: url("../../../assets/img/切图4.0/编组 17 (1).png") no-repeat;
    background-size: 75%;
    height: 70px;
    position: relative;
    left: 14.3%;
    bottom: 135px;
  }
}
</style>