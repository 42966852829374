<template>
  <div>
    <div class="all">
      <div class="content">
        <table :style="{'--uploadStyle':length+'s','--animation':animation}">
          <tr v-for="item in list" :key="item" :class="{red:item.healthCode=='红码'}">
            <td>{{item.perName}}</td>
            <td>{{item.temTime}}</td>
            <td>{{item.healthCode}}</td>
            <td>{{item.tem}}</td>
            <td>{{item.address}}</td>
          </tr>
        </table>

      </div>
    </div>

  </div>

</template>

<script>
import { getTodayDefend } from '@/api/whole'

import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      //数据
      list: [],
      length: null, //数据长度
      animation: true,//控制动画的停止与否
    }
  },
  created () {
    this.getTodayDefend()
    this.timer = setInterval(() => {
      this.getTodayDefend()
    }, 180000)
  },
  methods: {
    // 处理时间戳为年月日时分秒
    gettime (timestamp, gettype) {
      // console.log(timestamp, 'timestamp')
      var date = new Date(timestamp * 180000); //时间戳为10位需*180000，时间戳为13位的话不需乘180000
      if (gettype == "yyddmm") {
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10 ?
            "0" + (date.getMonth() + 1) :
            date.getMonth() + 1) + "-";
        var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + " ";
        var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":"
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      }
      // 算时间差
      if (gettype == "getcha") {
        // 两个时间戳相差的秒数
        var usedTime = timestamp * 180000;
        // console.log(usedTime,'usedTime')
        var hours = Math.floor(usedTime / (3600 * 180000));
        // var leave1 = usedTime % (3600 * 180000);
        // var minutes = Math.floor(leave1 / (60 * 180000))
      }
      return {
        yymmdd: Y + M + D + h + m + s,
        timecha: hours,
      };
    },
    async getTodayDefend () {
      let { data } = await getTodayDefend()
      console.log(data);
      let arr = []
      data.forEach(item => {
        item = {
          ...item,
          temTime: item.temTime ? item.temTime.substr(11) : '- -'
        }
        // if (item.perName.includes('*')) {
        //   return item.perName
        // } else {
        //   let name = []
        //   name.push(...item.perName)
        //   item.perName = name.length == 2 ? name[0] + '*' : name[0] + '*' + name.slice(-1)
        // }
        arr.push(item)
      })
      // console.log(arr);
      if (arr.length >= 8) {
        this.list = [...arr, ...arr]
        this.length = this.list.length
        this.animation = 'running'
      } else {
        this.list = arr
        this.length = this.list.length
        this.animation = 'paused'
      }
      // console.log(this.list);
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  margin-left: 6%;
}
table:hover {
  animation-play-state: paused;
}
.content {
  width: 90%;
  background: rgba(0, 0, 0, 0);
}
/* 通过CSS3动画滚动marquee */
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
table {
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);
  width: 85%;
  margin-top: 31px;
  margin-left: 13%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  tr {
    height: 42px;
    display: flex;
    td {
      text-align: center;
      flex: 1;
      // margin-top: 32px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      line-height: 41px;
      text-align: center;
    }
    td:nth-child(1),
    td:nth-child(5) {
      text-align: center;
      display: inline-block;
      width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 18%;
    }
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      text-align: center;
    }
    td:nth-child(5) {
      position: relative;
      left: 2%;
    }
  }
  .red {
    background: rgba(230, 56, 71, 0.26);
    border: 0px solid red;
    border-radius: 7px;
    td {
      color: rgba(255, 56, 74, 1);
    }
  }
}
</style>