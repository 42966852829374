import request from '../utils/request';
//获取设备概览
export const getBind = () => {
  return request({
    url: '/kanban/api/getBind',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//获取空气消毒机使用情况
export const totalTime = () => {
  return request({
    url: '/kanban/api/totalTime',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//健康码检测情况
export const todayCheck = () => {
  return request({
    url: '/kanban/api/todayCheck',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//体温数据监测
export const temMonitor = () => {
  return request({
    url: '/kanban/api/temMonitor',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//核酸检测时间监控
export const natTimeMonitor = () => {
  return request({
    url: '/kanban/api/natTimeMonitor',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//实时保护情况
export const nowUseTime = () => {
  return request({
    url: '/kanban/api/nowUseTime',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 近一周消毒面积
export const getAreas = () => {
  return request({
    url: '/kanban/api/getAreas',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//本月核销码数据记录
export const verification = () => {
  return request({
    url: '/kanban/api/verification',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//消毒水余量,设备运行轨迹,机器人使用情况
export const allowance = () => {
  return request({
    url: '/kanban/api/allowance',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//获取消毒面积
export const getWorking = () => {
  return request({
    url: '/kanban/api/getWorking',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//—— 近一周机器人使用记录 ——
export const record = () => {
  return request({
    url: '/kanban/api/record',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//疫苗接种情况
export const vaccineDetail = () => {
  return request({
    url: '/kanban/api/vaccineDetail',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//一体机设备使用情况
export const situation = () => {
  return request({
    url: '/kanban/api/situation',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//今日异常数据检测
export const abnormal = () => {
  return request({
    url: '/kanban/api/abnormal',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//7日内途径风险地区情况
export const risk = () => {
  return request({
    url: '/kanban/api/risk',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//今日检测记录
export const todayDefend = () => {
  return request({
    url: '/kanban/api/todayDefend',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//今日检测人数
export const todayDefends = (data) => {
  return request({
    url: '/kanban/api/todayDefends',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//每台设备累计消毒时长
export const getHours = () => {
  return request({
    url: '/kanban/api/getHours',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 近一周检测人次
export const weekPerson = () => {
  return request({
    url: '/kanban/api/weekPerson',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//气溶胶
export const aerosol = () => {
  return request({
    url: '/kanban/robot/aerosol',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post'
  });
};


