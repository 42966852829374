<template>
  <div>
    <div class="all">
      <div class="content" :style="{'--uploadStyle':length+'s','--animation':animation}">
        <div class="ani" v-for="item in list" :key="item">
          <p :class="{yunixng:item.yellowNum!=1,yunixng1:item.yellowNum==1}"> <span>{{item.defendPeriod}}</span>
            {{item.yellowNum}}</p>
          <el-progress stroke-width="6" :percentage="item.percentage">
            <span>{{item.greenNum}}h</span>
            <span>{{item.pro}}</span>

          </el-progress>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import { getnowUseTime } from '@/api/sterilizer'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      //数据
      list: [],
      //动画是否停止
      animation: true,
      //数据长度
      length: null,
    }
  },
  created () {
    this.getnowUseTime()
    this.timer = setInterval(() => {
      this.getnowUseTime()
    }, 180000)
  },
  methods: {
    async getnowUseTime () {
      let { data } = await getnowUseTime()
      // console.log(data);
      let arr = []
      data.forEach(item => {
        item = {
          ...item,
          percentage: item.greenNum / 7200 >= 1 ? '100' : (item.greenNum / 7200 * 100).toFixed(1),
          greenNum: (item.greenNum / 3600).toFixed(1),
          yellowNum: item.yellowNum == 1 ? '未运行' : '运行中',
          // xiaodu: item.greenNum > 2 ? '99.9%' : parseInt(item.greenNum * 100 / 2) + '%'
        }
        if (item.greenNum >= 2) {
          item.pro = '99.9%'
        } else if (item.greenNum == 0) {
          item.pro = '0%'

        } else {
          item.pro = (item.greenNum * 100 / 2).toFixed(1) + '%'
        }
        arr.push(item)

      });
      if (data.length < 3) {
        this.list = arr
        this.length = this.list.length
        this.animation = 'paused'
      } else {
        this.list = [...arr, ...arr]
        this.length = this.list.length

        this.animation = 'running'
      }

    },
    //保护率的格式
    // format () {
    //   let pro = null
    //   this.list.map(item => {
    //     pro = item.greenNum > 2 ? item.greenNum + 'h   99.9%' : parseInt(item.greenNum * 100 / 2) + '%'
    //   })
    //   return pro
    // }
  }
}
</script>

<style lang="scss" scoped>
// TODO:滚动问题

.content:hover {
  animation-play-state: paused;
}
.content {
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);

  width: 82%;
  background: rgba(0, 0, 0, 0);
  padding-left: 20%;
  p {
    position: relative;
    text-align: center;
    top: 24px;
    left: -33%;
    width: 214px;

    span {
      color: #ffffff;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      line-height: 22px;
      display: inline-block;
      width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }
  ::v-deep .el-progress {
    span:nth-child(1) {
      text-align: center;
      display: inline-block;
      width: 60px;
    }
    span:nth-child(2) {
      display: inline-block;
      width: 50px;
      text-align: center;
    }
  }
  ::v-deep .el-progress-bar__inner {
    background: linear-gradient(270deg, #f7da65 0%, #07ba74 100%);
  }
  ::v-deep .el-progress-bar__outer {
    margin-left: 28%;

    background: rgba(255, 255, 255, 0.2);
  }
  ::v-deep .el-progress__text {
    text-align: center;
    width: 120px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 25px;
    margin-right: 10px;
  }
}
/* 通过CSS3动画滚动marquee */
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
.yunixng {
  width: 48px;
  height: 22px;
  font-size: 16px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  line-height: 22px;
}
.yunixng1 {
  width: 48px;
  height: 22px;
  font-size: 16px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #fa6400;
  line-height: 22px;
}
</style>