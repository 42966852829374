<template>
  <div class="all">
    <div class="title">
      <span></span>
      <span>7日内途径风险地区情况</span>
      <span>{{ename}}</span>
    </div>
    <div class="content">
      <div class="one">
        <span>{{greenNum}}<span>人</span></span>
        <div class="img"> </div>
        <span>途径低风险地区</span>
      </div>
      <div class="two">
        <span>{{list.zg}}<span>人</span></span>
        <div class="img"></div>
        <span>途径中高风险地区</span>
      </div>
      <!-- <div class="three">
        <span>{{list.wei}}<span>人</span></span>
        <div class="img"></div>
        <span>未知</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getRisk } from '@/api/whole'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: "todayTime",
  mixins: [clearTime],
  props: {
    greenNum: {}
  },
  data () {
    return {
      ename: 'Situation of risk areas within 7 days',
      //数据
      list: {}
    }
  },
  created () {
    this.ename = this.ename.toUpperCase()
    this.getRisk()
    this.timer = setInterval(() => {
      this.getRisk()
    }, 180000)
  },
  methods: {
    async getRisk () {
      let { data } = await getRisk()
      console.log(data);

      this.list = data



    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  padding-left: 5%;
  width: 90%;
}
.title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 6px;
  margin-left: 12%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.content {
  width: 105%;
  margin-left: 12%;
  display: flex;
  justify-content: space-evenly;
  background: url("../../../assets/img/切图4.0/编组 11.png") no-repeat;
  background-size: 90%;
  padding-right: 6%;
  height: 230px;
  padding-left: 5%;
}
.one {
  flex: 0.33;
  height: 250px;
  text-align: center;
  margin-top: 20px;

  span:nth-child(1) {
    width: 88px;
    height: 48px;
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #08f3da;
    span {
      width: 17px;
      height: 23px;
      font-size: 17px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #08f3da;
      position: relative;
      bottom: 10px;
      left: 6px;
    }
  }
  .img {
    background: url("../../../assets/img/切图4.0/编组 16 (1).png") no-repeat;
    background-size: 100%;

    height: 130px;
    margin-top: -30px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    bottom: 15px;
  }
}
.two {
  flex: 0.33;
  height: 250px;
  text-align: center;
  margin-top: 20px;

  span:nth-child(1) {
    width: 88px;
    height: 48px;
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #f4ad04;
    span {
      width: 17px;
      height: 23px;
      font-size: 17px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #f4ad04;
      position: relative;
      bottom: 10px;
      left: 6px;
    }
  }
  .img {
    background: url("../../../assets/img/切图4.0/编组 16备份.png") no-repeat;
    background-size: 100%;

    height: 130px;
    margin-top: -30px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    bottom: 15px;
  }
}
.three {
  flex: 0.33;
  height: 250px;
  text-align: center;
  margin-top: 20px;

  span:nth-child(1) {
    width: 88px;
    height: 48px;
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;

    color: #e1e1e1;
    span {
      width: 17px;
      height: 23px;
      font-size: 17px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #e1e1e1;
      position: relative;
      bottom: 10px;
      left: 6px;
    }
  }
  .img {
    background: url("../../../assets/img/切图4.0/编组 16备份 2.png") no-repeat;
    background-size: 100%;

    height: 130px;
    margin-top: -30px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    bottom: 15px;
  }
}
</style>