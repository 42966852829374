<template>
  <div class="all">
    <div class="title">
      <span></span>
      <span>每台累计消毒时长</span>
      <span>{{ename}}</span>
    </div>
    <!-- 轮播组件 -->
    <el-carousel indicator-position="outside" height="90px" autoplay="flag" ref="dom" v-if="showcal">
      <el-carousel-item v-for="item in list" :key="item">
        <div class="content">

          <div class="one" v-for="key in item" :key="key">
            <span>{{parseInt(key.useTiem/3600)}}<span>h</span><br />
              <span>{{key.deviceName}}</span></span>
            <div class="img"></div>
          </div>
          <!-- <div class="two" v-if="item[1]">
            <span>{{item[1]? parseInt(item[1].useTiem/3600):'- -'}}<span>h</span><br />
              <span>{{item[1]? item[1].deviceName:'- -'}}</span></span>
            <div class="img"></div>
          </div>
          <div class="three" v-if="item[2]">
            <span>{{item[2]? parseInt(item[2].useTiem/3600):'- -'}}<span>h</span><br />
              <span>{{item[2]? item[2].deviceName:'- -'}}</span></span>
            <div class="img"></div>
          </div> -->
        </div>

      </el-carousel-item>
    </el-carousel>

  </div>
</template>

<script>
import { getSingelTime } from '@/api/sterilizer'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      ename: 'Accumulated disinfection duration',
      //数据
      list: [],
      //轮播图是否滚动
      flag: true,
      //组件显示控件
      showcal: false
    }
  },
  created () {
    this.ename = this.ename.toUpperCase()
    this.getSingelTime()
    this.timer = setInterval(() => {
      this.getSingelTime()
    }, 180000)
  },
  methods: {
    async getSingelTime () {
      this.list = []
      let { data } = await getSingelTime()
      this.list = data
      if (data.length > 3) {
        this.flag = true
      } else {
        this.flag = false
      }
      this.$nextTick(
        this.showcal = true
      )
      console.log(data);
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  overflow: hidden;
  width: 83%;
  .title {
    margin-top: 25px;
    margin-bottom: 45px;
    width: 100%;
    span:nth-child(1) {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 2px solid #08f4da;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin-right: 8px;
      margin-left: 4px;
    }
    span:nth-child(3) {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #08f4da;
      line-height: 22px;
    }
  }
  .content {
    width: 98%;
    display: flex;
    height: 160px;
    margin-left: 3%;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0);
  }
  ::v-deep .el-carousel {
    height: 160px;
  }
  ::v-deep .el-carousel__button {
    display: none;
  }
  ::v-deep .el-carousel__container {
    height: 130px !important;
  }
  .one {
    flex: 0.31;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 290px;

    .img {
      background: url("~@/assets/img/切图4.0.1/编组 4备份 3.png") no-repeat;
      background-size: 100%;
      height: 160px;
      position: relative;
      bottom: 50px;
    }
    span {
      display: inline-block;
      margin-bottom: -10px;
    }
    span:nth-child(1) {
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;
      color: #9365f6;
      line-height: 48px;
      span:nth-child(1) {
        font-size: 17px;
        font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
        font-weight: normal;
        color: #9365f6;
        position: relative;
        bottom: 10%;
      }
      span:nth-child(3) {
        display: inline-block;
        width: 114px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 20px;
      }
    }
  }
}
.title {
  margin-top: 59px;
  margin-left: 6%;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  // span:nth-child(2) {
  //   font-size: 16px;
  //   font-family: PingFangSC-Medium, PingFang SC;
  //   font-weight: 500;
  //   color: #ffffff;
  //   line-height: 22px;
  //   margin-right: 8px;
  //   margin-left: 4px;
  // }
  // span:nth-child(3) {
  //   font-size: 16px;
  //   font-family: PingFangSC-Semibold, PingFang SC;
  //   font-weight: 600;
  //   color: #08f4da;
  //   line-height: 22px;
  // }
}
</style>