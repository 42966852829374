<template>
  <div class="all pt37">
    <div class="one">
      <span>{{list1[0]
        ? list1[0].greenNum:'- -'}}</span>
      <span>{{list1[0]?  list1[0].defendPeriod:'- -'}}消毒机(台)</span>
    </div>
    <div class="two">
      <span>{{list1[1]
        ? list1[1].greenNum:'- -'}}</span>
      <span>{{list1[1]?  list1[1].defendPeriod:'- -'}}消毒机(台)</span>
    </div>
    <div class="three"> <span>{{list1[1]? list1[1].greenNum+list1[0].greenNum:'- -'}}</span>
      <span>设备总数(台)</span>
    </div>
    <div class="four"> <span>{{list1[1]? list1[1].redNum+list1[0].redNum:'- -'}}</span>
      <span>故障设备数(台)</span>
    </div>
    <div class="five">设备<br />类型</div>
  </div>
</template>

<script>

export default {
  name: 'deviceType',
  props: {
    list1: Array
  },
  data () {
    return {
      //类型数据
    }
  },
  created () {


  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.all {
  display: flex;
  flex-wrap: wrap;
}
.one {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 36%;
  height: 90px;
  background: #a6f4eb;
  background: url("~@/assets/img/切图4.0/编组 2.png") no-repeat;
  background-size: 100%;
  margin-bottom: 20px;
  margin-right: 20px;
  span:nth-child(1) {
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #08f4da;
    line-height: 48px;
  }
  span:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}

.two {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 36%;
  height: 90px;
  background: #a6f4eb;
  background: url("~@/assets/img/切图4.0/编组 2备份 2.png") no-repeat;
  background-size: 100%;
  span:nth-child(1) {
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #08f4da;
    line-height: 48px;
  }
  span:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}
.three {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;

  width: 36%;
  height: 90px;
  background: #a6f4eb;
  background: url("~@/assets/img/切图4.0/编组 2备份.png") no-repeat;
  background-size: 100%;
  span:nth-child(1) {
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #08f4da;
    line-height: 48px;
  }
  span:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}
.four {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 36%;
  height: 90px;
  background: #a6f4eb;
  background: url("~@/assets/img/切图4.0/编组 2备份 3.png") no-repeat;
  background-size: 100%;
  span:nth-child(1) {
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #08f4da;
    line-height: 48px;
  }
  span:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}
.five {
  width: 126px;
  height: 126px;
  transform: scale(0.8);
  background: linear-gradient(
    180deg,
    rgba(20, 76, 69, 0.3) 0%,
    rgba(18, 146, 132, 0.66) 100%
  );

  font-size: 21px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  padding-top: 30px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 63px;
  position: relative;
  right: 47%;
  bottom: 72px;
}
</style>