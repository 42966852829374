<template>
  <div class="all">
    <div class="title">
      <span></span>
      <span>体温数据监测</span>
      <span>{{ename}}</span>
    </div>
    <div class="content">
      <div class="one">
        <span>＜35℃</span>
        <span></span>
        <span>{{list.lowTemp}}</span>
        <span>人,{{list.lowTempP}}%</span>
      </div>
      <div class="one">
        <span>35℃<br /> ～37.2℃</span>
        <span></span>
        <span>{{list.normalTemp}}</span>
        <span>人,{{list.normalTempP}}%</span>
      </div>
      <div class="one">
        <span>＞37.2℃</span>
        <span></span>
        <span>{{list.highTemp}}</span>
        <span>人,{{list.highTempP}}%</span>
      </div>
      <div class="one">
        <span>体温异常<br /> 人数</span>
        <span></span>
        <span>{{list.errTemp}}</span>
        <span>人,{{list.errTempP}}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getTems } from '@/api/whole'
import clearTime from '@/mixin/clearTime.js'
export default {
  name:'tems',
  mixins: [clearTime],
  data () {
    return {
      ename: 'Temperature data monitoring',
      list: {}
    }

  },
  created () {
    this.ename = this.ename.toUpperCase()
    this.getTems()
    this.timer = setInterval(() => {
      this.getTems()
    }, 180000)
  },
  methods: {
    async getTems () {
      let { data } = await getTems()
      // console.log(data);
      data.lowTempP = data.lowTemp ? parseInt(data.lowTemp * 100 / data.allTemp) : 0
      data.highTempP = data.highTemp ? parseInt(data.highTemp * 100 / data.allTemp) : 0
      data.normalTempP = data.normalTemp ? parseInt(data.normalTemp * 100 / data.allTemp) : 0
      data.errTemp = data.lowTemp + data.highTemp
      data.errTempP = data.errTemp ? parseInt(((data.lowTemp + data.highTemp) * 100 / data.allTemp)) : 0
      this.list = data
      console.log(data);
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  padding-left: 10%;
}
.title {
  margin-top: 40px;
  margin-bottom: 6px;
  margin-left: 2%;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background: rgba(0, 0, 0, 0);
  .one {
    display: flex;
    width: 270px;
    height: 77px;
    padding-left: 2%;

    background: linear-gradient(
      90deg,
      rgba(19, 134, 250, 0.3) 0%,
      rgba(19, 134, 250, 0.05) 100%
    );
    border-radius: 10px;
    margin-top: 20px;
    span:nth-child(1) {
      flex: 0.6;
      width: 51px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 76px;
    }
    span:nth-child(2) {
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 48px;
      opacity: 0.2;
      border: 2px solid #ffffff;
      background: rgba(255, 255, 255, 0.2);
      margin-right: 5%;
      margin-top: 5%;
    }
    span:nth-child(3) {
      width: 39px;
      height: 48px;
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;
      color: #57c8ff;
      line-height: 76px;
    }
    span:nth-child(4) {
      width: 53px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #57c8ff;
      line-height: 76px;
      margin-left: 5%;
    }
  }
  .one:nth-child(2) {
    span:nth-child(1) {
      margin-top: 7%;
      line-height: 20px;
      text-align: center;
    }
  }
  .one:nth-child(4) {
    span:nth-child(1) {
      margin-top: 5%;
      line-height: 25px;
      text-align: center;
    }
  }
}
</style>