<template>
  <div class='box w628 h214 pt20 fs16 fw-500'>
    <div class="text-center">—— 核酸时间监测 ——</div>
    <template v-if="show">
      <div class="flex row-around">
        <div class="flex row-column col-center">
          <shuiqiu :percent='list[0].percent' ref="dom0" :popNum='totalPop' :people='list[0].val' />
          <div class="fs16 fw-500 mt10">{{list[0].title}}</div>
        </div>
        <div class="flex row-column col-center">
          <shuiqiu :percent='list[1].percent' ref="dom1" :popNum='totalPop' :people='list[1].val' />
          <div class="fs16 fw-500 mt10">{{list[1].title}}</div>
        </div>
        <div class="flex row-column col-center">
          <shuiqiu :percent='list[2].percent' ref="dom2" :popNum='totalPop' :people='list[2].val' />
          <div class="fs16 fw-500 mt10">{{list[2].title}}</div>
        </div>
        <!-- <div class="flex row-column col-center">
          <shuiqiu :percent='list[3].percent' ref="dom3" :popNum='totalPop' :people='list[3].val' />
          <div class="fs16 fw-500 mt10">{{list[3].title}}</div>
        </div> -->
        <!-- <div class="flex row-column col-center">
          <shuiqiu :percent='list[3].percent' ref="dom4" :popNum='totalPop' :people='list[3].val' />
          <div class="fs16 fw-500 mt10">{{list[3].title}}</div>
        </div> -->
      </div>
    </template>
  </div>
</template>

<script>
import shuiqiu from '@/components/shuiqiu/'
import { natTimeMonitor } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'centerBottom',
  components: { shuiqiu },
  mixins: [clearTime],
  data () {
    return {
      list: [
        {
          title: '48h内(人)',
          val: '',
          percent: [],
        },
        {
          title: '超48h(人)',
          val: '',
          percent: [],
        },
        {
          title: '未知(人)',
          val: '',
          percent: [],
        }],


      show: false,
      totalPop: ''
    };
  },

  created () {

  },

  mounted () {
    this.natTimeMonitor()
    this.timer = setInterval(() => {
      this.natTimeMonitor()
    }, 180000)
  },

  methods: {
    async natTimeMonitor () {
      const { data } = await natTimeMonitor()
      const totalNum = data.less24 + data.less48 + data.less72 + data.more72 + data.weizhi
      this.totalPop = totalNum
      const { less24, less48, less72, more72, weizhi } = data
      // const percent24 = (data.less24 / totalNum).toFixed(3) == 'NaN' ? 0 : (data.less24 / totalNum).toFixed(3)
      // this.list[0].val = data.less24
      // this.list[0].percent = [percent24, percent24]
      const percent48 = ((less24 + less48) / totalNum).toFixed(3) == 'NaN' ? 0 : ((less24 + less48) / totalNum).toFixed(3)
      this.list[0].val = (less24 + less48)
      this.list[0].percent = [percent48, percent48]
      const more48 = ((less72 + more72) / totalNum).toFixed(3) == 'NaN' ? 0 : ((less72 + more72) / totalNum).toFixed(3)
      this.list[1].val = (less72 + more72)
      this.list[1].percent = [more48, more48]
      // const percentMore72 = (data.more72 / totalNum).toFixed(3) == 'NaN' ? 0 : (data.more72 / totalNum).toFixed(3)
      // this.list[3].val = data.more72
      // this.list[3].percent = [percentMore72, percentMore72]
      const percentNone = (weizhi / totalNum).toFixed(3) == 'NaN' ? 0 : (weizhi / totalNum).toFixed(3)
      this.list[2].val = weizhi
      this.list[2].percent = [percentNone, percentNone]
      this.show = true
      this.$nextTick(() => {
        this.$refs.dom0.getDom()
        this.$refs.dom1.getDom()
        this.$refs.dom2.getDom()
        // this.$refs.dom3.getDom()
        // this.$refs.dom4.getDom()
      })

    }
  }
}

</script>
<style lang='scss' scoped>
.box {
  background: url("~@/assets/img/4.0/centerBottom.png") no-repeat;
  background-size: 100% 100%;
}
.text-center,
.mt10 {
  color: #fff;
}
</style>