<template>
  <div>
    <top :shouback="true" />
    <div class='container flex'>
      <div class="box_l flex1 h-max pt37  color-f">
        <!-- 设备类型 -->
        <deviceType class="deviceType" :list1="list1" />
        <!-- 设备使用记录 -->
        <div class="title2">
          <span></span>
          <span>设备使用记录</span>
          <span>EQUIPMENT USE RECORD</span>
        </div>
        <div class="header2">
          <span></span>
          <span>设备名称</span>
          <span>开启时间</span>
          <span>关闭时间</span>
          <span>已持续使用</span>
          <span></span>
        </div>
        <useprogress class="useprogress" />
        <!-- 每台累计消毒时长 -->
        <everyTime />
        <!-- 实时保护情况 -->
        <div class="title">
          <span></span>
          <span>实时保护情况</span>
          <span>REAL TIME PROTECTION</span>
        </div>
        <div class="header">
          <span></span>
          <span>设备名称</span>
          <span>运行状态</span>
          <span>持续保护时长(h)</span>
          <span>保护率(%)</span>
          <span></span>
        </div>
        <div style="padding-left:2%">
          <realTime class="realTime" />
        </div>
      </div>
      <div class="box_center w488 h-max pl20">
        <!-- 顶部条形图 -->
        <div class="titleImg"></div>

        <CountFlop :val='totalArea' title='今日检测人数' suffix='次' style="margin:0 auto" class="mt93 mr20" />
        <!-- 病毒消杀率 -->
        <display />
        <!-- —— 核酸时间监测 —— -->
        <!-- <robotUse /> -->
        <centerBottom class="centerBottom" />
      </div>
      <div class="box_r flex1 h-max">
        <!-- 体温数据监测 -->
        <tems />
        <!-- 健康码检测情况 -->
        <healthCode class="healthCode" @greenNum='greenNumFn' />
        <!-- 今日检测TODO:-->
        <div class="title1 mb20">
          <span></span>
          <span>今日检测记录</span>
          <span>TODAY'S TEST RECORD</span>
        </div>
        <div class="header1">
          <span></span>
          <span>姓名</span>
          <span>今日检测时间</span>
          <span>健康码</span>
          <span>体温(℃)</span>
          <span>设备位置</span>

          <span></span>
        </div>
        <todayjiance class="todayjiance" />
        <!-- 7日内途径风险地区情况 -->
        <!-- <todayTime :greenNum='greenNum' /> -->
      </div>
    </div>
  </div>

</template>

<script>
import top from '@/components/top.vue'
import deviceType from '@/views/Sterilizer/components/deviceType'
import useprogress from '@/views/Whole/components/useprogress.vue'
import everyTime from '@/views/Whole/components/everyTime.vue'
import realTime from '@/views/Whole/components/realTime.vue'
// import protectNum from '@/views/Sterilizer/components/protectNum'
import display from '@/views/Sterilizer/components/display'
// import robotUse from '@/views/SterilizerRobot/components/robotUse'
import healthCode from '@/views/Whole/components/healthCode'
import todayTime from '@/views/Whole/components/todayTime.vue'
import tems from '@/views/Whole/components/tems'
import todayjiance from '@/views/Whole/components/todayjiance'

import CountFlop from '@/components/CountFlop'
import { gettodayTotalDefendNum } from '@/api/whole'
import clearTime from '@/mixin/clearTime.js'
import centerBottom from '@/components/centerBottom'
import { getoverview } from '@/api/sterilizer'
export default {
  mixins: [clearTime],
  components: { top, deviceType, useprogress, everyTime, realTime, display, todayTime, tems, healthCode, todayjiance, CountFlop, centerBottom },
  data () {
    return {
      //累计消毒面积
      totalArea: '',
      list1: [],
      greenNum: ''
    };
  },

  created () {
    //获取今日检测人数
    this.gettodayTotalDefendNum()
    //获取设备概览
    this.getoverview()
    this.timer = setInterval(() => {
      this.gettodayTotalDefendNum()
      //获取设备概览
      this.getoverview()
    }, 180000)
  },

  mounted () { },

  methods: {
    async gettodayTotalDefendNum () {
      const { data } = await gettodayTotalDefendNum()
      // console.log(data);
      this.toOrderNum(data)
    },
    //转换千分位
    toOrderNum (num) {
      num = num.toString()
      if (num.length < 7) {
        num = '0' + num
        this.toOrderNum(num)
      } else {
        //加逗号
        num = num.slice(1, 4) + ',' + num.slice(4, 7)
        // this.count = num.split('') 
        this.totalArea = num
      }
    },
    async getoverview () {

      let { data } = await getoverview()
      this.list1 = data
    },
    greenNumFn (num) {
      this.greenNum = num
      // console.log(111, num);
    }
  }
}

</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 1004px;
  background: #000;
  perspective: 1800px;
  .box_l {
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
    background-size: 100% 100%;
    max-width: 720px;
  }
  .box_r {
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
    background-size: 100% 100%;
    max-width: 720px;
  }
}
.title2 {
  margin-left: 5%;

  margin-bottom: 6px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header2 {
  margin-left: 5%;

  width: 609px;
  height: 36px;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4),
  span:nth-child(5) {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
    vertical-align: bottom;
  }
  span:nth-child(2) {
    margin-left: 2%;
  }
  span:nth-child(3) {
    margin-left: 15%;
    margin-right: 18%;
  }

  span:nth-child(5) {
    margin-left: 10%;
  }
  span:nth-child(6) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    margin-left: 3.5%;
  }
}
.useprogress {
  height: 160px;
  overflow: hidden;
}
.title {
  padding-left: 5%;
  margin-bottom: 6px;
  margin-top: 20px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header {
  display: flex;
  margin-left: 5%;

  width: 83%;
  height: 36px;
  display: flex;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4),
  span:nth-child(5) {
    text-align: center;

    flex: 1;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 35px;
    vertical-align: bottom;
  }
  span:nth-child(2) {
    flex: 1.3;
  }
  span:nth-child(3) {
    flex: 1;
    margin-left: -2%;
  }
  span:nth-child(4) {
    flex: 3;
  }
  span:nth-child(5) {
    flex: 2;
  }

  span:nth-child(6) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
}
.realTime {
  height: 150px;
  overflow: hidden;
}
.deviceType {
  margin-left: 5%;
}
.title1 {
  margin-left: 15%;

  margin-bottom: 6px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header1 {
  margin-left: 15%;
  display: flex;
  width: 80%;
  height: 36px;
  text-align: center;

  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4),
  span:nth-child(5),
  span:nth-child(6) {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 32px;
  }
  span:nth-child(2),
  span:nth-child(4),
  span:nth-child(5) {
    flex: 1;
  }

  span:nth-child(6),
  span:nth-child(3) {
    flex: 1;
  }
  span:nth-child(7) {
    display: inline-block;
    margin-top: 1.2%;
    margin-right: 10px;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    margin-left: 3.5%;
  }
}
.todayjiance {
  height:300px;
  overflow: hidden;
}
.healthCode {
  height: 260px;
}
.titleImg {
  width: 330px;
  height: 12px;
  background: url("../../assets/img/切图4.0/头部配件.png") no-repeat;
  background-size: 100%;
  position: relative;
  top: 50px;
  left: 14%;
}
.center_b {
  margin-left: -20%;
  margin-top: -190px;
}
.centerBottom {
  position: relative;
  bottom: 18%;
  right: 19%;
}
</style>