import request from '../utils/request';

//获取设备概览

export const getoverview = () => {
  return request({
    url: '/kanban/api/getBind',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//设备开机总时长占比
export const gettotalUseTime = () => {
  return request({
    url: '/kanban/api/totalUseTime',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//实时消毒数据情况
export const getnowUseTime = () => {
  return request({
    url: '/kanban/api/nowUseTime',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//消毒机保护时长
export const gettotalTime = () => {
  return request({
    url: '/kanban/api/totalTime',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//每台累计消毒时长
export const getSingelTime = () => {
  return request({
    url: '/kanban/api/allUserTime',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//设备运行状态
export const getStatus = () => {
  return request({
    url: '/kanban/api/runningState',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//设备使用记录

export const getDeviceUse = () => {
  return request({
    url: '/kanban/api/airUse',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//今日保护时长
export const getProtectTime = () => {
  return request({
    url: '/kanban/api/todayProtect',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//机器人设备位置监测


export const getLocatin = () => {
  return request({
    url: '/kanban/robot/getNetLocation',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//机器人累计消毒时长
export const getWorkingHours = () => {
  return request({
    url: '/kanban/robot/getWorkingHours',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//近一周消毒时长

export const getRunningHours = () => {
  return request({
    url: '/kanban/robot/getRunningHours',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//近一周机器人使用记录

export const getWeekUser = () => {
  return request({
    url: '/kanban/robot/getWeekUser',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};