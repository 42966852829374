
import request from '../utils/request';

//获取体温数据监测

export const getTems = () => {
  return request({
    url: '/kanban/api/temMonitor',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//健康码检测情况
export const getCode = () => {
  return request({
    url: '/kanban/api/todayCheck',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//今日检测记录
export const getTodayDefend = () => {
  return request({
    url: '/kanban/api/todayDefend',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//7日内途径风险地区情况
export const getRisk = () => {
  return request({
    url: '/kanban/api/risk',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//核酸时间监测 
export const getNatTimeMonitor = () => {
  return request({
    url: '/kanban/api/natTimeMonitor',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//消毒时长

export const getHours = () => {
  return request({
    url: '/kanban/api/getHours',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//机器人消毒面积4.0
export const getAreas = () => {
  return request({
    url: '/kanban/api/getWorking',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//设备运行轨迹
export const getallowance = () => {
  return request({
    url: '/kanban/api/allowance',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//近一周消毒面积
export const getjinarea = () => {
  return request({
    url: '/kanban/api/getAreas',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//机器人使用记录
export const getdeviceUse = () => {
  return request({
    url: '/kanban/api/record',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//数字哨兵今日使用总数
export const gettodayTotalDefendNum = () => {
  return request({
    url: '/kanban/api/todayTotalDefendNum',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
