//时间戳转换为时间
const add0 = (m) => { return m < 10 ? '0' + m : m }
export const format = (shijianchuo) => {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo * 180000);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

// MB->GB
export function mbtogbmb (mb) {
  if (mb == '' || mb == undefined || mb == null) {
    return ''
  } else if (mb == 0) {
    return 0
  } else {
    let gb = parseInt(mb / 1024);
    let mb1 = mb % 1024;
    if (gb == 0) {
      return `${mb1}MB`
    } else {
      return `${gb}GB ${mb1}MB`;
    }
  }
}


//秒转换为时分秒
export const formatSeconds = (val) => {
  var theTime = parseInt(val);// 秒
  var theTime1 = 0;// 分
  var theTime2 = 0;// 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  var result = "" + parseInt(theTime) + "秒";
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  return result;
}

//数字补0
//转换千分位
export const toOrderNum = (num) => {
  num = num.toString()
  let num2 = ' '
  if (num.length < 7) {
    num = '0' + num
    toOrderNum(num)
  } else {
    //加逗号
    num2 = num.slice(1, 4) + ',' + num.slice(4, 7)
    console.log(num2);
    return num2
  }
}

export function formatDate (date, fmt = 'yyyy-MM-dd hh:mm:ss') {
  if (!(date instanceof Array)) {
    date = new Date(date)
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}
function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}
//标准时间转化为年月日
export function pickDate (data) {
  const date = new Date(data);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`
}

export function throttle(fn, wait = 300) {
  let context, args
  let previous = 0

  return function () {
    var now = +new Date()
    context = this
    args = arguments
    if (now - previous > wait) {
      fn.apply(context, args)
      previous = now
    }
  }
}