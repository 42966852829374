<template>
  <div>
    <div class="all">
      <div class="content">
        <table :style="{'--uploadStyle':length+'s','--animation':animation}">
          <tr v-for="item in list" :key="item">
            <td>{{item.name}}</td>
            <td>{{item.startTime}}</td>
            <td>{{item.finishTime}}</td>
            <td>{{item.workingTime}}h</td>
          </tr>
        </table>

      </div>
    </div>

  </div>

</template>

<script>
import { getDeviceUse } from '@/api/sterilizer'
import { formatDate } from '@/utils/pubilcjs'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      //数据
      list: [],
      length: null, //数据长度
      animation: true,//控制动画的停止与否
    }
  },
  created () {
    this.getDeviceUse()
    this.timer = setInterval(() => {
      this.getDeviceUse()
    }, 180000)
  },
  methods: {
    // 处理时间戳为年月日时分秒
    // gettime (timestamp, gettype) {
    //   // console.log(timestamp, 'timestamp')
    //   var date = new Date(timestamp * 180000); //时间戳为10位需*180000，时间戳为13位的话不需乘180000
    //   if (gettype == "yyddmm") {
    //     var Y = date.getFullYear() + "-";
    //     var M =
    //       (date.getMonth() + 1 < 10 ?
    //         "0" + (date.getMonth() + 1) :
    //         date.getMonth() + 1) + "-";
    //     var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + " ";
    //     var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
    //     var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":"
    //     var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
    //   }
    //   // 算时间差
    //   if (gettype == "getcha") {
    //     // 两个时间戳相差的秒数
    //     var usedTime = timestamp * 180000;
    //     // console.log(usedTime,'usedTime')
    //     var hours = Math.floor(usedTime / (3600 * 180000));
    //     // var leave1 = usedTime % (3600 * 180000);
    //     // var minutes = Math.floor(leave1 / (60 * 180000))
    //   }
    //   return {
    //     yymmdd: Y + M + D + h + m + s,
    //     timecha: hours,
    //   };
    // },
    async getDeviceUse () {
      let { data } = await getDeviceUse()
      let arr = []
      data.forEach(item => {
        item = {
          ...item,
          startTime: item.startTime == 0 || !item.startTime ? '--:--:--' : formatDate(item.startTime * 1000),
          finishTime: item.finishTime == 0 || !item.finishTime ? '--:--:--' : formatDate(item.finishTime * 1000),
          workingTime: item.workingTime ? parseInt(item.workingTime / 3600) : 0
        }
        arr.push(item)
      })
      // console.log(111, data);
      // console.log(data);

      // data.forEach(item => {
      //   item = {
      //     ...item,
      //     startTime: item.startTime ? this.gettime(item.startTime, "yyddmm").yymmdd : '--:--:--',
      //     finishTime: item.finishTime ? this.gettime(item.finishTime, "yyddmm").yymmdd : '--:--:--',
      //     workingTime: item.workingTime ? parseInt(item.workingTime / 3600) : 0
      //   }
      //   arr.push(item)

      // })
      // console.log(arr);
      if (data.length < 5) {
        this.list = arr
        this.length = this.list.length
        this.animation = 'paused'
      } else {
        this.list = [...arr, ...arr]
        this.length = this.list.length
        this.animation = 'running'

      }

    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);
  tr {
    height: 43px;
    display: flex;
    td {
      // margin-top: 32px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
    }
    td:nth-child(1) {
      text-align: start;
      display: inline-block;
      width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 18%;
    }
    td:nth-child(2) {
      width: 30%;
    }
    td:nth-child(3) {
      width: 32%;
    }
    td:nth-child(4) {
      width: 100px;
    }
  }
}
.all {
  margin-left: 6%;
}
table:hover {
  animation-play-state: paused;
}
.content {
  width: 85%;
  background: rgba(0, 0, 0, 0);
  p {
    position: relative;
    top: 24px;
    left: -33%;
    width: 129px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
  }
  // ::v-deep .el-progress-bar__inner {
  //   background: linear-gradient(270deg, #f7da65 0%, #07ba74 100%);
  // }
  // ::v-deep .el-progress-bar__outer {
  //   background: rgba(255, 255, 255, 0.2);
  // }
  // ::v-deep .el-progress__text {
  //   width: 40px;
  //   font-size: 18px;
  //   font-family: PingFangSC-Medium, PingFang SC;
  //   font-weight: 500;
  //   color: #ffffff;
  //   line-height: 25px;
  // }
}
/* 通过CSS3动画滚动marquee */
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
</style>