<template>
  <!-- 水球图 -->
  <div class="mainBox" ref="dom"></div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-liquidfill'
export default {
  name: 'shuiqiu',
  props: {
    // data数据
    percent: {
    },
    //人数
    popNum: {

    },
    people: {}
  },
  data () {
    return {};
  },

  components: {},

  created () { },

  mounted () {
  },

  methods: {
    getDom () {
      let myChart = echarts.init(this.$refs.dom)
      var option = {
        series: [{
          //水的颜色
          color: ['#059584'],
          type: 'liquidFill',
          data: this.percent,
          //球的大小
          radius: '95%',
          //圆环的颜色
          outline: {
            show: true,
            itemStyle: {
              color: 'none',
              borderColor: '#059584',
              borderWidth: 2,
            }
          },
          //背景颜色取消
          backgroundStyle: {
            borderColor: 'none',
            color: 'none'
          },
          //中间文字样式
          label: {
            formatter: (item) => {
              console.log(555, item);
              return this.people + '\n' + parseInt(item.value * 100) + '%'
            },
            fontSize: 26,
            fontWeight: 500,
            lineHeight: 40,
            fontFamily: 'PingFangSC-Medium, PingFang SC',
            color: '#fff'
          }
        }]
      };
      myChart.setOption(option)
    }
  }
}
</script>
<style lang='scss' scoped>
.mainBox {
  width: 109px;
  height: 111px;
}
</style>
